.root {
  position: relative;
  width: 100%;

  > label {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    cursor: pointer;
  }

  & input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid #fff;
    border-radius: 50%;
    outline: none;
    margin-bottom: 5px;
    cursor: pointer;
    background-color: transparent;
    position: relative;

    &:checked::before {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #518bcc; /* Цвет активной кнопки, например, розовый */
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.input {
  position: relative;
  font-weight: 400;
  outline: none;
  border: none;
  width: 100%;
  padding-bottom: 8px;
  border-bottom: 1px solid #91949a;
  background-color: transparent;
  font-size: 16px;
  color: #fff;
}
