/* установка ширины и цвета скроллбара */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  /* background-color: #1F1F1F; */
  background-color: transparent;
}

/* стилизация ползунка скроллбара */
::-webkit-scrollbar-thumb {
  /* background-color: #91949A; */
  background-color: transparent;
  border-radius: 5px;
}

/* стилизация трека скроллбара */
::-webkit-scrollbar-track {
  /* background-color: #1F1F1F; */
  -webkit-box-shadow: none !important;
  background-color: transparent;
}

/* Указываем box sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Убираем внутренние отступы b  */
ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: 400;
}

/* Убираем внешние отступы */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Выставляем основные настройки по-умолчанию для body */
body {
  background-color: #1f1f1f;
  font-family: Arial, Helvetica, sans-serif;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  color: #d4d5d6;
}

/* Предотвращение изменения размеров блоков и шрифтов */
html,
body {
  width: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

/* Элементы a сбрасываем до дефолтных стилей */
a {
  display: inline-block;
  text-decoration-skip-ink: auto;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

/* Упрощаем работу с изображениями */
img {
  max-width: 100%;
  display: block;
}

/* Указываем понятную периодичность в потоке данных у article*/
article > * + * {
  margin-top: 1em;
}

/* Наследуем шрифты для инпутов и кнопок */
input,
button,
textarea,
select {
  font: inherit;
}

button {
  padding: 0;
  background-color: inherit;
  border: none;
  font: inherit;
  outline: none;
}

/* Делает кнопку кликабельной и убирает отступы в Fire Fox */
button {
  cursor: pointer;
}
button::-moz-focus-inner {
  padding: 0;
  border: none;
}

/* Убирает крестик в Explorer в input */
input::-ms-clear {
  display: none;
}

figure {
  display: block;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
