.container {
  position: relative;
  padding-top: 60px;
  padding-bottom: 42px;

  .block {
    position: relative;
    width: 100%;
    height: calc(100vh - 120px);
    background-color: #1a1a1a;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;

    .top {
      position: fixed;
      height: 60px;
      top: 0;
      z-index: 2;
      width: 65vw;
      padding: 13px 20px;
      border: 1px solid #141414;
      border-left: none;
      background: #1f1f1f;
      color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5px;

      .username {
        align-self: center;
      }

      > * {
        display: flex;
        flex: 2;

        @media screen and (max-width: 768px) {
          &:nth-child(2) {
            justify-content: center;
          }
        }

        &:last-child {
          justify-content: flex-end;
        }
      }

      span {
        float: right;
        color: #7f91a4;
      }

      @media screen and (max-width: 768px) {
        > a {
          display: flex;
          align-items: center;
          gap: 4px;
          color: #518bcc;
          cursor: pointer;
        }

        width: 100vw;
      }
    }

    .chat {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 20px;
      // overflow-y: auto;
      > div {
        width: 100%;
      }

      .loader {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80vh;
      }

      .message {
        position: relative;
        max-width: 428px;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 17.5px;

        &__deleted {
          opacity: 0.5;
        }

        .red {
          color: rgb(225, 60, 60);
        }

        img {
          width: 200px;
        }

        p > div {
          width: 200px;
        }

        span {
          float: right;
          color: #7f91a4;
        }
      }

      .in {
        float: left;
        padding: 8px 8px 8px 14px;
        border-radius: 5px;
        background: #1f344b;
      }

      .out {
        float: right;
        padding: 8px 14px 8px 8px;
        border-radius: 5px;
        background: #284b64;
      }
    }

    .input {
      position: fixed;
      display: flex;
      gap: 24px;
      justify-content: space-between;
      align-items: center;
      bottom: 0;
      z-index: 2;
      width: 65vw;
      padding: 13px 20px;
      border: 1px solid #141414;
      border-left: none;
      border-right: none;
      background-color: #1f1f1f;

      > textarea {
        width: 100%;
        background: #1f1f1f;
        color: #fff;
        border: none;
        height: 32px;
        resize: none;

        &::placeholder {
          color: #6d7883;
        }

        &:focus {
          outline: none;
        }
      }

      > button {
        min-width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #518bcc;
        border-radius: 50%;

        > svg {
          display: block;
        }

        &:hover {
          background: #2b5278;
        }
      }

      @media screen and (max-width: 768px) {
        width: 100vw;

        > textarea {
          background-color: #444648;
          border-radius: 20px;
          padding: 8px 12px;
          height: 32px;
          resize: none;
        }
      }
    }
  }

  .searchModal {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 360px;

    span {
      white-space: nowrap;
    }

    > div {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 6px 10px;
      border-radius: 6px;
      background: #444648;

      .upArrow {
        transform: rotate(180deg);
      }

      input {
        width: 100%;
        border: none;
        font-size: 15px;
        background: none;
        color: #fff;

        &:focus {
          outline: none;
        }

        &::placeholder {
          color: #6d7883;
        }
      }
    }
  }

  .webmSticker {
    width: 100%;
  }
}

.messageWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.deleted {
  color: rgb(225, 37, 37);
  font-size: 12px;
}
