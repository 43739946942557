.modal {
  display: flex;
  flex-direction: column;
  gap: 20px;
  input {
    border: 1px solid white;
    padding: 10px 5px;
    border-radius: 10px;
    color: white;
    font-size: 16px;
    background-color: transparent;
  }
  select {
    background-color: #17212b;
    border: 1px solid white;
    border-radius: 10px;
    color: #ffffff;
    padding: 10px 5px;
    outline: nonw;
  }
  .create {
    border: 1px solid white;
    padding: 10px;
    color: white;
    font-size: 16px;
    border-radius: 10px;
    transition: all 0.3s;
    &:hover {
      background-color: #0e1621;
      border: 1px solid #0e1621;
    }
  }
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100vh;
  background-color: #1f1f1f;
  border-right: 1px solid #141414;

  .search {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px;

    .menu {
      display: flex;
      cursor: pointer;
      width: 20px;
      height: 10px;
      flex-direction: column;
      justify-content: space-between;

      div {
        width: 100%;
        height: 1px;
        background-color: white;
        transition: 0.3s all;
      }
    }

    input {
      width: 100%;
      color: #fff;
      background-color: #444648;
      border-radius: 45px;
      padding: 9px 19px;
      border: none;

      &::placeholder {
        color: #91949a;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .dialogs {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;

    &__scroll {
      &::-webkit-scrollbar {
        width: 5px;
        height: 10px;
        background-color: #1f1f1f;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #91949a;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-track {
        background-color: #1f1f1f;
        -webkit-box-shadow: none !important;
      }
    }

    .loader {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow-y: hidden;
      height: 80vh;
    }
  }

  .spamList {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-right: 10px;
  }

  .spam {
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: scroll;

    &__el {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      overflow: auto;

      > p {
        font-size: 18px;
        overflow: hidden;
      }

      > button {
        border: 1px solid white;
        padding: 6px 10px;
        color: white;
        font-size: 16px;
        border-radius: 4px;
        transition: all 0.3s;
      }
    }

    > h3 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 12px;
    }
  }
}
