.root {
  width: 100%;
  padding: 16px 32px;
  border-radius: 4px;
  background-color: #242f3d;
  color: #e1e3e7;
  font-weight: 500;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  transition: all 0.3s;
  box-shadow: 1px 5px 10px #10151b;

  &:hover {
    box-shadow: none;
    background: #71abeb;
  }
}

.primary {
  background: #518bcc;
}

.disabled {
  background: #444648;
}
