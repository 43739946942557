.activeChat {
  background-color: #444648;
}

.contextMenu {
  display: flex;
  flex-direction: column;
  background-color: #111;
  border-radius: 8px;
  position: absolute;
  z-index: 3;
  top: 20px;
  left: -290px;
  width: 318px;
  overflow: hidden;

  button {
    padding: 16px;
    width: 100%;
    color: #fff;
    font-size: 14px;
    text-align: left;
    display: flex;
    gap: 10px;
    align-items: center;

    &:hover {
      background-color: #323232;
    }
  }
}

.block {
  position: relative;
  cursor: pointer;
  width: 100%;
  padding: 10px 12px;
  display: flex;
  gap: 12px;
  align-items: center;
  transition: 0.1s;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: inherit;

  .image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 47px;
    height: 47px;
    border-radius: 25px;

    > span {
      color: #fff;
      font-weight: 500;
      font-size: 30px;
      line-break: 30px;
    }
  }

  .content {
    width: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-size: 12px;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > div {
        display: flex;
        align-items: center;
      }

      .account {
        padding: 2px 3px;
        border-radius: 5px;
        background-color: #7f91a4;
      }

      .title {
        display: flex;
        gap: 4px;
        overflow: hidden;

        .nickname {
          background-color: #5d5f60;
          padding: 4px;
          border-radius: 4px;
        }

        > h4 {
          color: #fff;
          font-size: 14px;
          font-weight: 600;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      > span {
        color: #91949a;
      }
    }

    .message {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.25rem;
    }

    .messageMeta {
      > div {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .dotsWrapper {
        position: relative;
        padding-left: 8px;
      }
    }

    p {
      color: #91949a;
      font-size: 14px;
      font-weight: 400;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &:not(.activeChat) {
    &:hover {
      background-color: #2a2a2a;
    }
  }

  .unread {
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 4px;
    right: 4px;
    background-color: #518bcc;
    padding: 4px 7px;
    border-radius: 100px;
  }
}
