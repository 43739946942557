.block {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 44px;
    width: 100%;
    max-width: 400px;
    padding: 30px;
    -webkit-appearance: none;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;

    .project {
      display: flex;
      gap: 16px;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
      font-weight: 400;
      font-size: 16px;
    }

    input,
    label {
      // display: block;
      // width: 100vw;
      // -webkit-appearance: none;
      border-radius: 0;

      &:-webkit-autofill,
      &:-webkit-autofill:focus {
        transition: background-color 0s 600000s, color 0s 600000s !important;
      }
    }

    h1 {
      color: #e1e3e7;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
