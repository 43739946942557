
.active {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: 0.5s;
  pointer-events: all;
  z-index: 10;
  .container {
    padding: 20px;
    border-radius: 15px;
    background-color: #17212b;
    .closeModal {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      img {
        cursor: pointer;
      }
    }
    .content {
      padding: 50px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      max-height: 80vh;
    }
  }
}

.modal {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
}
