.wrapper {
  background: #0e1621;
  padding: 20px 0;
  max-width: 300px;
  width: 100%;
  z-index: 1;
  max-height: 100vh;
  min-width: 220px;
  position: relative;
  height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  .exit {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    font-size: 18px;
    cursor: pointer;
    border-bottom: 1px solid white;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .el {
    display: flex;
    align-items: center;
    padding: 12px 20px;
    cursor: pointer;
    transition: 0.3s;
    gap: 8px;

    div {
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      border-radius: 4px;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    &_mod {
      display: flex;
      align-items: center;
      justify-content: left;
      background: #242f3d;
    }

    &:hover {
      background: #242f3d;
    }
  }
}
