.users {
  width: 100%;
  overflow: auto;
  padding: 30px;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h1 {
      font-size: 25px;
    }

    .buttons {
      display: flex;
      gap: 30px;
    }

    .addAcc {
      border: 1px solid white;
      padding: 10px;
      color: white;
      font-size: 16px;
      border-radius: 10px;
      transition: all 0.3s;
    }
  }

  .table {
    width: 100%;

    tr:nth-child(2n) {
      background-color: #0e1621;
    }

    tr {
      text-align: center;
      font-size: 16px;
      cursor: pointer;

      th {
        padding: 20px 0;
        border-bottom: 1px solid white;
      }

      td {
        padding: 10px 0;
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  input {
    border: 1px solid white;
    padding: 10px 5px;
    border-radius: 10px;
    color: white;
    font-size: 16px;
    background-color: transparent;
  }
  select {
    background-color: #17212b;
    border: 1px solid white;
    border-radius: 10px;
    color: #ffffff;
    padding: 10px 5px;
    outline: nonw;
  }
  .create {
    border: 1px solid white;
    padding: 10px;
    color: white;
    font-size: 16px;
    border-radius: 10px;
    transition: all 0.3s;
    &:hover {
      background-color: #0e1621;
      border: 1px solid #0e1621;
    }
  }
}
