.row {
  display: flex;
  overflow-y: hidden;
  height: 100vh;

  > div {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    > div:first-child {
      width: 35vw;
    }

    > div:last-child {
      width: 65vw;
    }
  }
}
