.menuList {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  background-color: #1A1A1A;

  width: 35%;
  height: 100vh;
  z-index: 2;
  transform: translate(-100%);
  opacity: 0;
  transition: all 0.5s ease;

  &__open {
    transform: translate(0) ;
    opacity: 1 ;
  }
}

.navLinks {
  padding: 50px 0px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 16px;
  font-weight: 600;
  line-height: 130%;
  div {
    cursor: pointer;
  }
}
