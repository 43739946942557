.container {
  display: flex;
  max-height: 100vh;
  .navBars {
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 220px;
    gap: 30px;
  }
  .content {
    width: 100%;
    overflow-y: scroll;
    padding: 30px;
  }
}
